<template>
	<section class="home-footer">
		<div class="home-upper-footer">
			<ul class="footer-upper-menu">
				<li>
					<router-link to="/about-us">{{ $t("nav.o nas") }}</router-link>
				</li>
				<!-- <li>Realizacje</li> -->
				<li>
					<router-link to="/team">{{ $t("nav.zespol") }}</router-link>
				</li>
				<!-- <li>Blog</li> -->
				<li>
					<router-link to="/products">{{ $t("nav.produkty") }}</router-link>
				</li>
				<li>
					<router-link to="/contact">{{ $t("nav.kontakt") }}</router-link>
				</li>
			</ul>
		</div>
		<div class="home-footer-right-side">
			<h5>{{ $t("footer.kontakt") }}</h5>
			<ul>
				<li>Jan Szweda</li>
				<li>jan.szweda@etrustmedical.com</li>
				<li>+48 500 312 506</li>
			</ul>
		</div>
		<ul class="footer-bottom-menu">
			<li>
				<a href="https://www.facebook.com/etrustmedical" target="_blank">Facebook</a>
			</li>
			<li>
				<a href="https://pl.linkedin.com/company/etrustmedical?trk=public_profile_topcard-current-company" target="_blank"
					>Linkedin</a
				>
			</li>
			<!-- <li><router-link to="/">English</router-link></li> -->
			<li>
				<router-link to="/privacy-policy">{{ $t("footer.polityka") }}</router-link>
			</li>
		</ul>
		<img src="~@/assets/images/etrust-logo.svg" alt="Etrust-medical" />
	</section>
	<section class="home-footer-small">
		<div>
			<p>eTrustMedical &copy; 2022</p>
			<p><a href="https://www.minima.pl/" target="_blank">Site by Minima Advertising People</a></p>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.home-footer {
	height: auto;
	background-color: $dark-blue;
	display: grid;
	grid-template-columns: 800px 400px;
	justify-content: center;
	padding: 45px 0 46px 0;
	h5 {
		color: white;
		font-size: $fontSize23;
		font-family: "Roobert-SemiBold";
		margin: 0 0 27px 27px;
	}
	ul {
		list-style: none;
		margin-bottom: 45px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		li,
		a {
			color: white;
			font-size: $fontSize23;
			line-height: 27px;
			letter-spacing: 0px;
			transition: all 200ms ease-out;
			display: block;
			position: relative;
		}
		@include respond-above(lg) {
			a:after {
				content: "";
				position: absolute;
				width: 101%;
				transform: scaleX(0);
				height: 1px;
				left: 0;
				bottom: 2px;
				background-color: white;
				transform-origin: bottom right;
				transition: transform 0.25s ease-out;
			}
			a:hover:after {
				transform: scaleX(1);
				transform-origin: bottom left;
			}
		}
	}
	&-right-side {
		ul {
			padding-left: 27px;
		}
	}
	.footer-bottom-menu {
		margin-bottom: 0;
		li,
		a {
			font-size: $fontSize16;
			line-height: 19.2px;
		}
	}
	img {
		width: 360px;
		height: 58px;
		margin: auto 0 0 27px;
		filter: invert(100%) sepia(100%) saturate(0%);
	}
	@include respond-below(xl) {
		grid-template-columns: 42.5vw 42.5vw;
		&-right-side {
			text-align: left;
		}
		img {
			justify-self: start;
		}
	}
	@include respond-below(lg) {
		grid-template-columns: 85vw;
		grid-template-rows: auto;
		ul {
			li,
			a {
				font-size: $fontSize20;
			}
		}
		h5 {
			font-size: $fontSize20;
			margin: 0;
			line-height: 35px;
		}
		img {
			width: 300px;
			height: 50px;
		}
		&-right-side {
			order: -1;
			text-align: left;
			ul {
				padding-left: 0px;
			}
		}
		img {
			justify-self: start;
			margin: 30px 0 0 0;
		}
	}
	@include respond-below(sm) {
		background-color: $dark-blue;
		padding: 57px 0 27px 30px;
		justify-content: start;
		ul {
			margin-bottom: 55px;
			li {
				line-height: 26px;
				font-size: $fontSize15;
				letter-spacing: 0px;
			}
		}
		h5 {
			font-size: $fontSize15;
			line-height: 26px;
		}
		.footer-bottom-menu {
			li {
				font-size: $fontSize15;
				line-height: 26px;
			}
		}
		img {
			width: 190px;
			height: 30px;
			margin: 55px 0 0 0;
			justify-self: start;
		}
	}
}

.home-footer-small {
	background-color: #130464;
	padding: 18px 0;
	height: auto;
	div {
		margin: 0 auto;
		display: grid;
		width: 1200px;
		grid-template-columns: 1fr 1fr;
	}
	p,
	a {
		color: $light-blue;
		font-size: $fontSize12;
		&:nth-child(2) {
			text-align: right;
		}
	}
	@include respond-below(xl) {
		margin: 0 auto;
		div {
			width: 85vw;
		}
	}
	@include respond-below(sm) {
		background-color: $dark-blue;
		padding: 18px 0;
		padding-bottom: 30px;
		div {
			width: 85vw;
			grid-template-columns: 85vw;
		}
		p {
			&:nth-child(2) {
				text-align: left;
			}
		}
	}
}
</style>
