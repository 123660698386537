<template>
    <HomeBanner />
    <AboutUsBanner />
    <AboutUsOverview />
    <ProductsOverview />
    <AboutUsWork />
    <AboutUsImplementations />
    <AboutUsTitle />
    <AboutUsWorkflow />
    <AboutUsDescription />
</template>

<script>
import HomeBanner from "@/components/home-components/HomeBanner.vue";
import AboutUsBanner from "@/components/about-us-components/AboutUsBanner.vue";
import AboutUsOverview from "@/components/about-us-components/AboutUsOverview.vue";
import AboutUsWork from "@/components/about-us-components/AboutUsWork.vue";
import ProductsOverview from "@/components/about-us-components/ProductsOverview.vue";
import AboutUsImplementations from "@/components/about-us-components/AboutUsImplementations.vue";
import AboutUsTitle from "@/components/about-us-components/AboutUsTitle.vue";
import AboutUsWorkflow from "@/components/about-us-components/AboutUsWorkflow.vue";
import AboutUsDescription from "@/components/about-us-components/AboutUsDescription.vue";


export default {
    components: {
        HomeBanner,
        AboutUsBanner,
        AboutUsOverview,
        AboutUsWork,
        ProductsOverview,
        AboutUsImplementations,
        AboutUsTitle,
        AboutUsWorkflow,
        AboutUsDescription
    }
}
</script>
