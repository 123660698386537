import { createStore } from "vuex";

export default createStore({
    state: {
        screenWidth: null,
    },
    mutations: {
        setWidth(state, payLoad) {
            state.screenWidth = payLoad;
        },
    },
    actions: {
        setscreenWidth({ commit }, payLoad) {
            commit("setWidth", payLoad);
        },
    },
    modules: {},
});