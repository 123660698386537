<template>
    <Header />
    <Description />
    <GalleryHeader />
    <Gallery />
    <ProductsHeader />
    <ProductsShowcase />
    <ConctactGrid />
</template>

<script>
import Header from "@/components/optimalization/Header.vue";
import Description from "@/components/optimalization/Description.vue";
import GalleryHeader from "@/components/optimalization/GalleryHeader";
import Gallery from "@/components/default/Gallery.vue";
import ProductsHeader from "@/components/optimalization/ProductsHeader.vue";
import ProductsShowcase from "@/components/default/ProductsShowcase.vue";
import ConctactGrid from "@/components/default/ContactGrid.vue";

export default {
    components: {
        Header,
        Description,
        GalleryHeader,
        Gallery,
        ProductsHeader,
        ProductsShowcase,
        ConctactGrid,
    },
};
</script>
