<template>
	<header class="page-header">
		<div class="line">
			<span class="span-color-1"></span>
			<span class="span-color-2"></span>
			<span class="span-color-3"></span>
		</div>
		<div class="page-header-container">
			<router-link to="/"
				><img
					:src="require(`@/assets/images/` + $t('logoPath'))"
					class="mobile-menu-transition"
					alt="etrustmedical"
					@click="menuStatus = false"
			/></router-link>
			<div class="header-right">
				<div class="header-right-upper-menu" v-if="this.screenWidth >= 1220">
					<nav>
						<ul>
							<li>
								<a v-if="$i18n.locale === 'pl-PL'" @click="$i18n.locale = 'en-US', redirect('en')">English</a>
								<a v-if="$i18n.locale === 'en-US'" @click="$i18n.locale = 'pl-PL', redirect('pl')">Polski</a>
							</li>
							<li>
								<a href="https://pl.linkedin.com/company/etrustmedical?trk=public_profile_topcard-current-company" target="_blank"
									><img src="@/assets/images/linkedin.svg" alt="LinkedIn"
								/></a>
							</li>
							<li>
								<a href="https://www.facebook.com/etrustmedical" target="_blank"
									><img src="@/assets/images/fb.svg" alt="Facebook"
								/></a>
							</li>
						</ul>
					</nav>
				</div>
				<div class="header-right-lower-menu" v-if="this.screenWidth >= 1220">
					<nav>
						<ul>
							<li>
								<router-link to="/about-us" :class="$route.name === 'about-us' ? 'active' : null">{{
									$t("nav.o nas")
								}}</router-link>
							</li>
							<li>
								<router-link to="/products" :class="$route.name === 'products' ? 'active' : null">{{
									$t("nav.produkty")
								}}</router-link>
							</li>
							<li>
								<router-link to="/team" :class="$route.name === 'team' ? 'active' : null">{{ $t("nav.zespol") }}</router-link>
							</li>
							<li>
								<router-link to="/contact" :class="$route.name === 'contact' ? 'active' : null">{{
									$t("nav.kontakt")
								}}</router-link>
							</li>
						</ul>
					</nav>
				</div>
				<nav class="nav-mobile" v-if="this.screenWidth < 1220">
					<div class="navbar">
						<div class="container nav-container">
							<input class="checkbox" type="checkbox" id="menuCheckbox" @click="toggleMenu" />
							<div class="hamburger-lines">
								<span class="line line1"></span>
								<span class="line line2"></span>
								<span class="line line3"></span>
							</div>
						</div>
						<transition name="mobile-menu-animation">
							<div class="mobile-content" v-if="menuStatus">
								<ul>
									<div>
										<li @click="toggleMenu">
											<router-link to="/about-us">{{ $t("nav.o nas") }}</router-link>
										</li>
										<li @click="toggleMenu">
											<router-link to="/products">{{ $t("nav.produkty") }}</router-link>
										</li>
										<li @click="toggleMenu">
											<router-link to="/team">{{ $t("nav.zespol") }}</router-link>
										</li>
										<li @click="toggleMenu">
											<router-link to="/contact">{{ $t("nav.kontakt") }}</router-link>
										</li>
									</div>
									<div class="eng-icons-container">
										<li @click="toggleMenu">
											<a v-if="$i18n.locale === 'pl-PL'" @click="$i18n.locale = 'en-US'">English</a>
											<a v-if="$i18n.locale === 'en-US'" @click="$i18n.locale = 'pl-PL'">Polski</a>
										</li>
										<div>
											<li @click="toggleMenu">
												<a
													href="https://pl.linkedin.com/company/etrustmedical?trk=public_profile_topcard-current-company"
													target="_blank"
													><img src="@/assets/images/linkedinmobile.svg" alt="LinkedIn"
												/></a>
											</li>
											<li @click="toggleMenu">
												<a href="https://www.facebook.com/etrustmedical" target="_blank"
													><img src="@/assets/images/fbmobile.svg" alt="Facebook"
												/></a>
											</li>
										</div>
									</div>
								</ul>
							</div>
						</transition>
					</div>
				</nav>
			</div>
		</div>
	</header>
</template>
<script>
export default {
	data() {
		return {
			menuStatus: false,
		};
	},
	computed: {
		screenWidth() {
			return this.$store.state.screenWidth;
		},
	},
	watch: {
		menuStatus() {
			this.unTick();
		},
	},
	methods: {
		redirect(lang) {
			let path = this.$route.path;
			if (path.includes('pl')) path = path.replace('pl', lang)
			else if (path.includes('en')) path = path.replace('en', lang)
			else path = `/${lang}/` + path
			this.$router.push({ path: path })
		},
		toggleMenu() {
			this.menuStatus = !this.menuStatus;
			if (this.menuStatus === true) {
				document.querySelector("body").style.overflowY = "hidden";
				document.querySelector(".page-header").style.backgroundColor = "#133ad8";
				document.querySelector(".mobile-menu-transition").style.filter = "brightness(0) invert(1) sepia(2) saturate(0)";
				document.querySelector(".line1").style.backgroundColor = "#FFFFFF";
				document.querySelector(".line3").style.backgroundColor = "#FFFFFF";
				document.querySelector(".span-color-1").style.backgroundColor = "#133ad8";
				document.querySelector(".span-color-2").style.backgroundColor = "#133ad8";
				document.querySelector(".span-color-3").style.backgroundColor = "#133ad8";
			}
		},
		unTick() {
			if (this.menuStatus === false) {
				document.getElementById("menuCheckbox").checked = false;
				document.querySelector("body").style.overflowY = "initial";
				document.querySelector(".page-header").style.backgroundColor = "#FFFFFF";
				document.querySelector(".mobile-menu-transition").style.filter = "initial";
				document.querySelector(".line1").style.backgroundColor = "#133ad8";
				document.querySelector(".line3").style.backgroundColor = "#133ad8";
				document.querySelector(".span-color-1").style.backgroundColor = "#0397ff";
				document.querySelector(".span-color-2").style.backgroundColor = "#133ad8";
				document.querySelector(".span-color-3").style.backgroundColor = "#1d0799";
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.active {
	border-bottom: 1px $primary-blue solid;
}

.page-header {
	width: 100vw;
	background-color: white;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100000;
	transition: all 0.5s ease;
	&-container {
		height: 100px;
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		a {
			position: relative;
		}
		img {
			height: 44px;
			width: 269px;
		}
		nav {
			ul {
				list-style: none;
				text-decoration: none;
				display: flex;
				li {
					padding: 3px 6px;
				}
				a {
					cursor: pointer;
				}
			}
		}
		.header-right {
			li {
				align-self: center;
				display: block;
				position: relative;
			}
			&-upper-menu {
				display: flex;
				justify-content: end;
				font-size: $fontSize11;
				img {
					width: 20px;
					height: 20px;
					margin-left: -4px;
				}
			}
			&-lower-menu {
				font-size: $fontSize14;
				li:after {
					content: "";
					position: absolute;
					width: calc(100% - 11px);
					transform: scaleX(0);
					height: 1px;
					bottom: 2px;
					left: 6px;
					right: 6px;
					background-color: $primary-blue;
					transform-origin: bottom right;
					transition: transform 0.25s ease-out;
				}
				li:hover:after {
					transform: scaleX(1);
					transform-origin: bottom left;
				}
			}
		}
		@include respond-above(fhd) {
			width: 80vw;
		}
		@include respond-below(xl) {
			width: 85vw;
		}
		@include respond-below(sm) {
			height: 80px;
			img {
				width: 186px;
				height: 30px;
			}
		}
	}
}

.nav-mobile {
	.mobile-content {
		position: fixed;
		z-index: 9999999;
		top: 105px;
		bottom: 0;
		right: 0;
		left: 0;
		background: $primary-blue;
		ul {
			height: 100%;
			width: 85vw;
			margin: 0 auto;
			padding: 30px 0;
			list-style: none;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;
			li {
				margin: 10px 0;
				font-size: $fontSize30;
				a {
					color: white;
				}
			}
			.eng-icons-container {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				div {
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						width: 44px;
						height: 44px;
					}
				}
			}
		}
	}
	.nav-container {
		display: block;
		position: relative;
		height: 60px;
	}
	.nav-container .checkbox {
		position: relative;
		display: block;
		height: 60px;
		width: 60px;
		z-index: 5;
		opacity: 0;
		cursor: pointer;
		background-color: transparent;
		color: transparent;
	}
	.nav-container .hamburger-lines {
		display: block;
		height: 26px;
		width: 36px;
		position: absolute;
		top: 17px;
		left: 15px;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.nav-container .hamburger-lines .line {
		display: block;
		height: 1px;
		width: 100%;
		border-radius: 10px;
		background-color: $primary-blue;
	}
	.nav-container .hamburger-lines .line1 {
		transform-origin: 0% 0%;
		transition: transform 0.4s ease-in-out;
	}
	.nav-container .hamburger-lines .line2 {
		transition: transform 0.2s ease-in-out;
	}
	.nav-container .hamburger-lines .line3 {
		transform-origin: 0% 100%;
		transition: transform 0.4s ease-in-out;
	}
	.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
		transform: rotate(45deg);
	}
	.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
		transform: scaleY(0);
	}
	.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
		transform: rotate(-45deg);
	}
	@include respond-below(sm) {
		.mobile-content {
			top: 85px;
		}
	}
}
.mobile-menu-animation-enter-from {
	opacity: 0;
}
.mobile-menu-animation-enter-active {
	transition: all 0.5s ease;
}
.mobile-menu-animation-enter-leave-to {
	opacity: 1;
}
.mobile-menu-animation-leave-from {
	opacity: 1;
}
.mobile-menu-animation-leave-active {
	transition: all 0.5s ease;
}
.mobile-menu-animation-leave-to {
	opacity: 0;
}
</style>
