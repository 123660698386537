<template>
    <SelectedPersonBanner />
    <SelectedPersonExperience />
    <!-- <HomeGallery /> -->
</template>

<script>
import SelectedPersonBanner from "@/components/team-selected-person/michal-kasiewicz/SelectedPersonBanner.vue";
import SelectedPersonExperience from "@/components/team-selected-person/michal-kasiewicz/SelectedPersonExperience.vue";
// import HomeGallery from "@/components/home-components/HomeGallery.vue";
export default {
    components: {
        SelectedPersonBanner,
        SelectedPersonExperience,
        // HomeGallery,
    },
};
</script>
