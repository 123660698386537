<template>
    <ContactHeader />
    <ContactGrid />
</template>

<script>
import ContactHeader from "@/components/contact-components/ContactHeader.vue";
import ContactGrid from "@/components/contact-components/ContactGrid.vue";

export default {
    components: {
        ContactHeader,
        ContactGrid,
    },
};
</script>
