<template>
    <ProductsHeader />
    <ProductsShowcase />
    <ProductsBanAir />
    <ProductsBanAirTitle />
    <ProductsOverview />
</template>

<script>
import ProductsHeader from "@/components/products-components/ProductsHeader.vue";
import ProductsShowcase from "@/components/default/ProductsShowcase.vue";
import ProductsBanAir from "@/components/products-components/ProductsBanAir.vue";
import ProductsBanAirTitle from "@/components/products-components/ProductsBanAirTitle.vue";
import ProductsOverview from "@/components/products-components/ProductsOverview.vue";

export default {
    components: {
        ProductsHeader,
        ProductsShowcase,
        ProductsBanAir,
        ProductsBanAirTitle,
        ProductsOverview,
    },
};
</script>
