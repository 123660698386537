import { createI18n /** LocaleMessageDictionary **/ } from "vue-i18n";

const loadLocalesData = () => {
	const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const localeData = {
		datetimeFormats: {},
		numberFormats: {},
		messages: {},
	};
	const keys = locales.keys();

	keys.forEach((key) => {
		const matched = key.match(/\.\/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			localeData.messages[locale] = locales(key);
		}
	});

	return localeData;
};

const data = loadLocalesData();

export const i18n = createI18n({
	locale: "pl-PL",
	fallbackLocale: "en-US",
	messages: data.messages,
	datetimeFormats: data.datetimeFormats,
	numberFormats: data.numberFormats,
	fallbackWarn: false,
	missingWarn: false,
	legacy: true,
	warnHtmlInMessage: "off",
});

export const availableLocales = [
	{
		name: "Polski",
		locale: "pl-PL",
		flag: "pl",
		selected: true,
	},
	{
		name: "USA",
		locale: "en-US",
		flag: "us",
		selected: false,
	},
];

export default i18n;
