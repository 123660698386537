<template>
    <Header />
    <Description />
    <Overview />
    <ProductsOverview />
    <GalleryHeader />
    <Gallery />
    <ProductsHeader />
    <ProductsShowcase />
    <ContactGrid />
</template>

<script>
import Header from "@/components/products-radban-components/Header.vue";
import Description from "@/components/products-radban-components/Description.vue";
import Overview from "@/components/products-radban-components/Overview.vue";
import ProductsOverview from "@/components/products-radban-components/ProductsOverview.vue";
import GalleryHeader from "@/components/products-radban-components/GalleryHeader.vue";
import Gallery from "@/components/default/Gallery.vue";
import ProductsHeader from "@/components/products-radban-components/ProductsHeader.vue";
import ProductsShowcase from "@/components/default/ProductsShowcase.vue";
import ContactGrid from "@/components/default/ContactGrid.vue";

export default {
    components: {
        Header,
        Description,
        Overview,
        ProductsOverview,
        GalleryHeader,
        Gallery,
        ProductsHeader,
        ProductsShowcase,
        ContactGrid,
    },
};
</script>
