<template>
    <TeamBanner />
    <TeamGroups />
</template>

<script>
import TeamBanner from "@/components/team-components/TeamBanner.vue";
import TeamGroups from "@/components/team-components/TeamGroups.vue";

export default {
    components: {
        TeamBanner,
        TeamGroups
    },
};
</script>
