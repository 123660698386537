import { createRouter, createWebHistory } from "vue-router";

import { i18n } from '../i18n/index'

import HomePage from "@/layouts/HomePage.vue";
import AboutUs from "@/layouts/AboutUs.vue";
import Products from "@/layouts/Products.vue";
import Team from "@/layouts/Team.vue";
import Contact from "@/layouts/Contact.vue";
import ProductsKorban from "@/layouts/ProductsKorban.vue";
import TeamMariuszBudzisz from "@/layouts/TeamMariuszBudzisz.vue";
import TeamMichalKasiewicz from "@/layouts/TeamMichalKasiewicz.vue";
import TeamPawelGawronski from "@/layouts/TeamPawelGawronski.vue";
import ProductsRadban from "@/layouts/ProductsRadban.vue";
import ProductsECRF from "@/layouts/ProductsECRF.vue";
import ProductsHospanel from "@/layouts/ProductsHospanel.vue";
import ProductsSpecific from "@/layouts/ProductsSpecific.vue";
import NotFound from "@/components/default/NotFound.vue";
import PrivacyPolicy from "@/components/default/PrivacyPolicy.vue";
import Optimalization from "@/layouts/Optimalization.vue";

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			try {
				window.scrollTo({ top: 0 });
				const yOffset = -120;
				const element = document.querySelector(to.hash);
				const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				return new Promise((resolve, reject) => {
					setTimeout(() => {
						resolve(window.scrollTo({ top: y, behavior: "smooth" }));
					}, 300);
				});
			} catch (e) {}
		} else {
			return window.scrollTo({ top: 0 });
		}
	},
	routes: [
		{
			name: "home",
			path: "/:lang?/",
			component: HomePage,
			meta: {
				title: 'eTrustMedical',
				canonoical: null,
			},
		},
		{
			name: "about-us",
			path: "/:lang?/about-us",
			component: AboutUs,
			meta: {
				title: "O nas",
				canonoical: null,
			},
		},
		{
			name: "products",
			path: "/:lang?/products",
			component: Products,
			meta: {
				title: "Produkty",
				canonoical: null,
			},
		},
		{
			name: "team",
			path: "/:lang?/team",
			component: Team,
			meta: {
				title: "Zespół",
				canonoical: null,
			},
		},
		{
			name: "contact",
			path: "/:lang?/contact",
			component: Contact,
			meta: {
				title: "Kontakt",
				canonoical: null,
			},
		},
		{ name: "sorban", path: "/:lang?/products/sorban", component: ProductsKorban },
		{
			name: "mariusz-budzisz",
			path: "/:lang?/team/mariusz-budzisz",
			component: TeamMariuszBudzisz,
		},
		{
			name: "michal-kasiewicz",
			path: "/:lang?/team/michal-kasiewicz",
			component: TeamMichalKasiewicz,
		},
		{
			name: "pawel-gawronski",
			path: "/:lang?/team/pawel-gawronski",
			component: TeamPawelGawronski,
		},
		{ name: "radban", path: "/:lang?/products/radban", component: ProductsRadban },
		{ name: "ecrf", path: "/:lang?/products/ecrf", component: ProductsECRF },
		{
			name: "hospanel",
			path: "/:lang?/products/hospanel",
			component: ProductsHospanel,
		},
		{
			name: "specific",
			path: "/:lang?/products/specific",
			component: ProductsSpecific,
		},
		{
			name: "optimalization",
			path: "/:lang?/products/optimalization",
			component: Optimalization,
		},
		{
			name: "privacy-policy",
			path: "/:lang?/privacy-policy",
			component: PrivacyPolicy,
		},
		{
			name: "not-found",
			path: "/:lang?/:pathMatch(.*)*",
			component: NotFound,
		},
	],
});

router.beforeEach((to, from, next) => {
	if(to?.meta?.title) {
		document.title = to?.meta?.title || "eTrustMedical";
	}
	let redirectTo
	if (to.params.lang != 'pl' && to.params.lang != 'en') {
		let lang = i18n.global.locale == 'pl-PL' ? 'pl' : 'en'
		redirectTo = `/${lang}${to.fullPath}`
		next({ path: redirectTo })
		return
	} else {
		if (to.params.lang == 'pl') {
			i18n.global.locale = 'pl-PL'
		} else if (to.params.lang == 'en') {
			i18n.global.locale = 'en-US'
		}
	}
	next()
  });

export default router;