<template>
    <HomeBanner />
    <HomeAbout />
    <HomeProductsHeader />
    <ProductsShowcase />
    <HomeAchievements />
    <!-- <HomeGallery />
    <HomeBlog /> -->
</template>

<script>
import HomeBanner from "@/components/home-components/HomeBanner.vue";
import HomeAbout from "@/components/home-components/HomeAbout.vue";
import HomeProductsHeader from "@/components/home-components/HomeProductsHeader.vue";
import ProductsShowcase from "@/components/default/ProductsShowcase.vue";
import HomeAchievements from "@/components/home-components/HomeAchievements.vue";
import HomeGallery from "@/components/home-components/HomeGallery.vue";
import HomeBlog from "@/components/home-components/HomeBlog.vue";

export default {
    components: {
        HomeBanner,
        HomeAbout,
        HomeProductsHeader,
        ProductsShowcase,
        HomeAchievements,
        HomeGallery,
        HomeBlog,
    },
};
</script>
